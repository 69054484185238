import React, { useRef, useState, useCallback } from "react";
import { Grid } from "@mui/material";
import { navigate } from "gatsby";
import BackButtonIcon from "../images/arrow.svg";

const ViewPage = (props) => {
  const url = props?.location?.state?.url;
  const [isPlaying, setIsPlaying] = useState(false);
  const videoRef = useRef(null);

  const playorPause = useCallback(() => {
    if (videoRef.current.paused || videoRef.current.ended) {
      videoRef.current.play();
    } else {
      videoRef.current.pause();
    }
  }, []);

  const onPlay = useCallback(() => setIsPlaying(true), []);

  const onPause = useCallback(() => setIsPlaying(false), []);
  return (
    <Grid
      container
      direction="column"
      justifyContent="flex-start"
      style={{ backgroundColor: "black", height: '98vh' }}
    >
      <Grid item>
        <Grid
          container
          direction="row"
          alignItems="flex-start"
          style={{ width: "100%" }}
          onClick={() => navigate("/")}
        >
          <Grid item>
            <img src={BackButtonIcon} style={{ width: 20 }} />
          </Grid>
          <Grid item style={{ marginLeft: 10, color: "white" }}>
            Back
          </Grid>
        </Grid>
      </Grid>
      <Grid item style={{ width: "100%" }}>
        <video controls src={url} style={{ width: "95%", height: "95%" }} />
      </Grid>
    </Grid>
  );
};

export default ViewPage;
